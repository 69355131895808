<template>
  <gw-dialog v-model="dialog" :loading="loading" @open:dialog="onOpen">
    <template #title>
      <v-card-title>
        <div class="text-h5" v-text="$t('invoice.email')" />
      </v-card-title>
      <v-card-subtitle>
        <div class="text-body" v-text="$t('invoice.email.info')" />
      </v-card-subtitle>
    </template>

    <v-form v-if="dialog" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="loading"
        hide-bottom-actions
        hide-top-actions
        no-nest
        @cancel="onClose"
        @save="onSend"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <form-field-email v-model="sEmail" multiple required />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="bSaveOnCustomer"
                :label="$t('save.on.customer')"
              />
            </v-col>
          </v-row>
        </v-container>
      </gw-form-observer>
    </v-form>

    <template #prepend-actions>
      <v-btn
        :disabled="!canSend"
        :loading="loading"
        color="primary"
        text
        @click="onSend"
        v-text="$t('send')"
      />
      <v-btn
        :disabled="canSend"
        :loading="loading"
        class="ml-2"
        color="primary"
        text
        @click="onSend"
        v-text="$t('send.without.email')"
      />
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import type { FirmData } from "@planetadeleste/vue-mc-gw/src/types";
import { isEmpty, set } from "lodash";
import { InvoiceModule } from "@/store/invoice";

@Component({
  components: { GwDialog },
})
export default class InvoiceCustomerEmail extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;

  loading = false;
  sEmail: string | null = null;
  bSaveOnCustomer = true;

  get obInvoice() {
    return InvoiceModule.invoice;
  }

  get obCustomer(): FirmData {
    return this.obInvoice.get("customer_firm");
  }

  get validEmails() {
    const reg =
      /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(,\s{0,1}){0,1})+$/;
    return reg.test(this.sEmail || "");
  }

  get canSend() {
    return !isEmpty(this.sEmail) && this.validEmails;
  }

  get emailRules() {
    return isEmpty(this.sEmail) || this.validEmails
      ? []
      : ["Formato de correo/s invalido/s. Recuerde separarlos por coma."];
  }

  async onOpen() {
    if (!this.obInvoice?.id) {
      return;
    }

    if (this.obCustomer?.email) {
      this.sEmail = this.obCustomer.email;
    }
  }

  onClose() {
    this.sEmail = null;
    this.$emit("input", false);
  }

  async onSend() {
    if (this.sEmail && this.obInvoice) {
      const obCustomerData = { ...this.obCustomer };
      obCustomerData.email = this.sEmail;

      if (this.bSaveOnCustomer) {
        set(obCustomerData, "store_email", 1);
      }

      this.obInvoice.set("customer_firm", obCustomerData);
    }

    this.onClose();
    this.$emit("send");
  }
}
</script>
